import React from 'react';

import { LottieAnimation } from 'components/animation';

import animation from './json/index.plk.json';

const Animation = ({ isPaused = false }) => (
  <LottieAnimation options={{ animationData: animation }} isPaused={isPaused} />
);

export default Animation;
