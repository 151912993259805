import React from 'react';

/* A note when using this component - you will need to pass a fill color if you want anything other than black. */

const CluckNorris = (props: { [key: string]: any }) => (
  <svg data-dir="ltr" viewBox="0 0 109.8 131" {...props}>
    <title>Popeyes Chicken</title>
    <path d="M80.5,18.8c1.1-0.5,1.6-1.9,1.2-3.3c-0.1-0.2-0.1-0.4-0.2-0.5c-0.7-1.5-2.3-2.2-3.6-1.6c-0.4,0.2-0.7,0.5-0.9,0.8 c-0.5,0.8-0.6,1.9-0.1,3C77.6,18.7,79.2,19.4,80.5,18.8z M88.7,28.8c3.7,7.4,16.2,32.3,17.7,37c4.7,15.5,4.1,33.9-18.3,46 c-6.9,3.7-13.3,5.6-19.1,6.1l-1.1,12h-8.4l-1.2-12.5C43,114.7,33,103.1,29.7,96.7c-0.1-0.3-0.3-0.5-0.4-0.8 c-0.2-0.3-0.3-0.6-0.4-0.9c-6.2-14.9,6.7-32.5-4.3-39.2c0,0-7.2-4.8-12.3,3.8c0,0.1-0.1,0.1-0.1,0.2l-11.7-13 c8.7-12.6,24.1-23.8,36.8-16c19.2,11.8-0.2,35.8,11.6,43.1c0,0,7.1,4.5,12.7-4.7c2.9-4.9,4.1-12.5,4.4-15.4l0.1-38.3l-1.8,0.7 c-0.1,0.1-0.4,0.1-0.6,0.2c-0.3,0.1-0.6,0.2-0.9,0.2c-0.8,0.2-1.5,0.2-2.3,0.2c-3.8-0.2-6.1-1.9-7.5-3.8c-0.7-0.8-1.2-1.8-1.5-3 c-0.2-0.7-0.3-1.4-0.2-2.1c0-0.1,0-0.2,0-0.4c0.3-3.9,3.3-6.8,6.6-6.5c2.3,0.2,4.1,1.8,5,4.1c0.8-1,1.9-1.9,3.5-2.4 c5.3-1.9,8.6,3,10.2,6.2L88.9,4l-1.6,7.5l5.3,2.8l-0.5,0.3l-3.2,2.9c2.5,1.6,5.3,4.5,3.4,8.6C91.7,27.8,90.3,28.6,88.7,28.8 L88.7,28.8z" />
  </svg>
);

export default CluckNorris;
