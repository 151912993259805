import React from 'react';

import CluckNorris from 'components/icons/cluck-norris';

interface IIcon {
  width?: number;
}

const Icon = ({ width }: IIcon) => {
  const s = width || 160;
  return <CluckNorris width={s} height={s} />;
};

export default Icon;
